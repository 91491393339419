import React from "react";
import { graphql } from "gatsby";
import * as PublicationGridStyling from "./PublicationGrid.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";

const PublicationGrid = ({ block, isTextContent }) => {
  const { title, subtitle, publications } = block
  return <section>
    <div className={`${isTextContent ? "row" : "layout-row"} normal-element`}>
      <h2 children={title} />
      {subtitle && <h3 children={subtitle} />}
      <div className="row">
        {publications.map((link, i) => <a href={link.url} key={i} className="col c-6 sm-4 plain mb-6">
          <GatsbyImage
            className={`${PublicationGridStyling.publication__image} mb-5`}
            image={link.image.gatsbyImageData}
            alt={link.image.alt}
          />
          {link.pressrelease && <p
            className="subtle text2 mb-1"
            children="PRESS RELEASE"
          />}
          <p
            className="mb-3 text5"
            children={link.title}
          />
          {link.publishingdate && <p
            className="subtle text3 mt-0 mb-0"
            children={`Posted on ${link.publishingdate}`}
          />}
          {!link.pressrelease && !!link.timetoread && <p
            className="subtle text3 mt-0 mb-0"
            children={`Time to read - ${link.timetoread}`}
          />}
        </a>)}
      </div>
    </div>
  </section>
}

export default PublicationGrid

export const query = graphql`
  fragment PublicationGrid on DatoCmsPublicationgrid {
    model {
      apiKey
    }
    id: originalId
    title
    publications {
      title
      url
      image {
        title
        alt
        gatsbyImageData
      }
      pressrelease
      publishingdate
      timetoread
    }
  }
`;